@font-face {
  font-family: "Proxima_Nova_Regular";
  src: url("./font/ProximaNovaRegular.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima_Nova_Bold";
  src: url("./font/ProximaNovaBold.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima_Nova_Medium";
  src: url("./font/ProximaNovaMedium.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima_Nova_Light";
  src: url("./font/ProximaNovaLight.woff") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --color-primary: teal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Proxima_Nova_Regular", Fallback, sans-serif;
}
